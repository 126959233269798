// Generated by Framer (6d7081c)

import { addFonts, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-io176"

const variantClassNames = {hkHKzz6bB: "framer-v-1l1rp7k"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "hkHKzz6bB", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1l1rp7k", className, classNames)} data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"hkHKzz6bB"} ref={ref ?? ref1} style={{backdropFilter: "blur(32px)", background: "linear-gradient(270deg, rgba(255, 255, 255, 0.02) 0%, rgba(5, 5, 5, 0.2) 36.423444976076524%, rgba(255, 255, 255, 0.02) 100%)", WebkitBackdropFilter: "blur(32px)", ...style}}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-io176.framer-1ne4o9r, .framer-io176 .framer-1ne4o9r { display: block; }", ".framer-io176.framer-1l1rp7k { height: 279px; overflow: hidden; position: relative; width: 80px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 279
 * @framerIntrinsicWidth 80
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerkI94BfmyT: React.ComponentType<Props> = withCSS(Component, css, "framer-io176") as typeof Component;
export default FramerkI94BfmyT;

FramerkI94BfmyT.displayName = "Glass";

FramerkI94BfmyT.defaultProps = {height: 279, width: 80};

addFonts(FramerkI94BfmyT, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})